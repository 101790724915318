import {QBMediaVideo} from "./media/video";
import {QBMediaAudio} from "./media/audio";
import {QBMediaImage} from "./media/image";
import {QBMediaPlaceholder} from "./media/placeholder";
import {QBMediaDocument} from "./media/document";
import {FileChecksum} from "./helper/file_checksum";
import QBEditMediaAuditive from "~/qb_v2/media/edit/auditive";
import {ExtAudioReverse} from "~/qb_v2/media/edit/auditive_task";
import QBEditMediaVisual from "~/qb_v2/media/edit/visual";


export class QBMedia {
    static get setup () {
        QB.Media = this;
        new QBObserver().subtrees('figure[data-attribute^="media"]', node=>this.init(node))
    }

    static video() { return QBMediaVideo }
    static audio() { return QBMediaAudio }
    static image() { return QBMediaImage }
    static document() { return QBMediaDocument }
    static placeholder() { return QBMediaPlaceholder }
    static get mime_types() {
        return Object.assign({},
            QBMediaVideo.mime_types,
            QBMediaAudio.mime_types,
            QBMediaImage.mime_types,
            QBMediaDocument.mime_types
        );
    }

    static init(element) {
        element = element.closest('figure[data-attribute^="media"]');
        const m = element.className.match(/\b(video|audio|image|placeholder)\b/);
        if(!m) return;
        const media = this[m[0]]().init(element);
        media.edit = !!element.closest(('.media-edit'));
        return media;
    }

    static render({content_type, ...data}, target) {
        return this.klass(content_type).render(data, target);
    }

    static update_tree({content_type, object_id, attribute, index, ...data}) {
        const cat = `.media.${this.category(content_type)}`
        document.querySelectorAll(`[id='${object_id}'] ${cat}, [data-id='${object_id}'] ${cat}`).forEach(container => {
            const div = container .querySelector(`[data-index='${index}']`) || cDIV({data: { index: index }, append: container  }),
                element = div.querySelector(`[data-attribute=${attribute}]`) || this.render({content_type, object_id, attribute, index, ...data}, div)
        });
    }

    static edit(element) {
        const type = element.dataset.action.match(/_(auditive|visual)_?.*$/)[1],
            edit = element.closest('.edit'),
            media = edit.querySelector(`aside.media.${type}`),
            klass = edit.dataset.klass;

        if(klass) {
            const [editor, module_name] = klass.split(/::/);
            if(module_name === 'ExtAudioReverse') return new ExtAudioReverse(media);
        }
        switch(type) {
            case "auditive":
                return new QBEditMediaAuditive(media);
            case "visual":
                return new QBEditMediaVisual(media);

        }

    }

    static klass(content_type) {
        switch(content_type.replace(/\/.*/, '')) {
            case 'placeholder': return this.placeholder();
            case 'audio': return this.audio();
            case 'video': return this.video();
            case 'image': return this.image();
        }
    }

    static type(content_type) {
        const type = content_type.replace(/\/.*/, "");
        if(/audio|image|video/.test(type)) return type;
        return (this.mime_types[content_type] || [])[2];
    }

    static category(content_type) {
        switch(content_type.replace(/_\d+$/, '')) {
            case 'media_visual':
                return 'visual';
            case 'media_auditive':
                return 'auditive';
        }
        switch(this.type(content_type)) {
            case 'image':
            case 'video':
                return 'visual';
            case 'audio':
                return 'auditive';
        }
    }

    static create({content_type, ...data}) {
        return this.klass(content_type).create({content_type, ...data});
    }

    static file({file, media, ...data}) {
        if(!media) media = this.create({content_type: file.type, ...data});
        media.src = URL.createObjectURL(file);
        media.edit = true;
        this.file_upload(file, media);
    }

    static file_upload(file, media) {
        FileChecksum.create(file, (_, checksum) => {
            media.checksum = checksum;
            media.upload;
        });
    }

    static url({url, ...data}) {
        url = new URL(url);
        if(url.pathname.startsWith("/watch?=") && url.hostname !== "www.youtube.com") url.hostname = "www.youtube.com";
        if(/www\.youtube\.com$|youtu\.be$/.test(url.hostname))
            return this.url_youtube({url, ...data});
        else if(/wiki(p|m)edia\.org$/.test(url.hostname))
            return this.url_commons({url, ...data});
        else if(document.location.hostname == url.hostname)
            return this.url_own({url, ...data});
        else
            return this.url_other({url, ...data});
    }

    static url_youtube({url, ...data}) {
        const youtube_id = url.searchParams.get("v") || url.pathname.substr(1);
        let media;
        if (data.target.closest('.media.auditive'))
            media = this.url_youtube_audio({url, youtube_id, ...data});
        else
            media = this.url_youtube_video({url, youtube_id, ...data});
        media.checksum = media.content = `youtube:${youtube_id}`;
        media.edit = true;
        media.upload;
    }
    static url_youtube_audio({url, youtube_id, ...data}) {
        return this.create({content_type: "audio/mpeg", ...data});
    }
    static url_youtube_video({url, youtube_id, ...data}) {
        const media = this.create({content_type:  "video/mp4", ...data});
        media.poster = `https://i.ytimg.com/vi/${youtube_id}/hq720.jpg`;
        return media;
    }

    // xxx(){
    //     console.log(data, embed_id);
    //     return
    //     const process = ()=> {
    //         document.body.focus();
    //
    //         let promise;
    //         promise = QB.confirm(__("Choose to upload and process the clip."), {
    //             title: __("Process video"),
    //             "audio/mpeg": __("Audio only"),
    //             "video/mp4": __("As is"),
    //             "video/mp4;noaudio": __("Visuals only")
    //         });
    //         promise.then(content_type => {
    //             if(!content_type) return;
    //             const m = content_type.match(/(no)?audio/),
    //                 media_id = m ? `${embed_id}/${m[0]}` : media_id;
    //
    //             const media_name = QBEditorMedia.media_name(attribute, content_type),
    //                 media = QBEditorMedia.figure_for_drop(content_type, {
    //                     src: "",
    //                     data: { content_type: content_type },
    //                     poster: QBEditorMedia.embed_poster(media_id)
    //                 }, media_name, { data: { id: media_id, checksum: media_id } });
    //             if (!media) return;
    //
    //             QBEditorMedia. insert_figure(editor, object, media_name, media);
    //
    //             const path = editor.path(id, 'media/embed');
    //             QBEditorMedia.fetch('put', path, media).catch(()=>{
    //                 QB.alert = __("Drop not successful.");
    //                 media.remove();
    //             });
    //         });
    //     };
    //     setTimeout(process, 10);
    // }

    static url_commons({url, ...data}) {

    }
    static url_own({url, ...data}) {

    }
    static url_other({url, ...data}){

    }

    static swap({id, from, to}) {
        const sel = `[data-attribute="${from.attribute}"][data-index="${from.index}"]`;
        [document.getElementById(id), document.querySelector(`[data-id="${id}"]`)].forEach(c=>{
            if(!c) return;
            const figure = c.querySelector(sel);
            if(!figure) return;
            const media = QB.Media.init(figure);
            media.swap_with(to);
        });
    }

    static media_update({content_type, id, src, attribute, index, subindex, ...data}) {
        [document.getElementById(id), document.querySelector(`[data-id="${id}"]`)].forEach(container=>{
            if(container) container = container.querySelector(`.${this.category(attribute)}`);
            if(!container) return;
            const target = container.querySelector(`div[data-index="${index}"]`) || cDIV({data: {index: index}, append: container}),
                figure = target.querySelector(`figure:not(.placeholder)[data-attribute="${attribute}"]`);
            let media;
            if(figure) media = QB.Media.init(figure);
            else if(src === false) return;
            else media = this.create({ content_type, target, attribute, index, subindex });
            media.update({id, attribute, index, src, content_type, ...data});
        });
    }


    static update({id, attribute, index, subindex, src, content_type, indices, to, from, ...data}) {
        console.log("media#update", {id, attribute, index, subindex, src, content_type, indices, to, from, ...data})

        if(indices !== undefined) {
            const task = document.getElementById(id),
                selector = attribute.replace('_', '.'),
                media = task.querySelector(`aside.${selector}`);

            switch(attribute.replace(/^media_/, '')) {
                case "auditive":
                    return new QBEditMediaAuditive(media).indices = indices;;
                case "visual":
                    return new QBEditMediaVisual(media).indices = indices;;

            }

        }
        else if(to !== undefined) this.swap({id, from, to});
        else this.media_update( { content_type, id, src, attribute, index, subindex, ...data });
    }


    static receive_uploader_started(data) {
        QB.notice = __("Processing started");
    }
    static receive_uploader_finished({uploader_id, src, value, line, status, ...data}) {
        console.log('receive_uploader_finished', {uploader_id, src, value, line, status, ...data});
        document.querySelectorAll(`figure[data-updater="${uploader_id}"]`).forEach(figure=>{
            if(figure.media) {
                figure.media.src = src;
                figure.media.progress('finished', status, line);
            }
        });
        QB.notice = __("Processing finished");
    }
    static receive_uploader_failed({uploader_id, error, status, ...data})     {
        QB.alert = __("Processing failed");
        console.log('receive_uploader_failed', data);
        document.querySelectorAll(`figure[data-updater="${uploader_id}"]`).forEach(figure=>{
            if(!figure.media) return;
            figure.dataset.status = 'failed';
            figure.media.progress(true, 'failed', error)
        });
    }
    static receive_uploader_progress({uploader_id, value, line, eta, status, poster, title, ...data})   {
       document.querySelectorAll(`figure[data-updater="${uploader_id}"]`).forEach(figure=>{
           if(!figure.media) return;
           figure.media.progress(value, status, line, eta);
           figure.media.poster = poster;
           if(!title) return;
           const solution = figure.closest('[data-klass="Task::ExtMusic"]')?.querySelectorAll(".solution-field .solution");
           if(!solution || [...solution].some(s=>s.textContent.trim())) return;
           const [artist,song] = title.split(/\s+-\s+/);
           if(solution[1]) {
               solution[1].textContent = song;
               solution[0].textContent = artist;
           }
           else solution[1].textContent = `${song} | ${artist}`;
       });


    }

    static receive_uploader_cancel({uploader_id, value, line, status, ...data}) {
        console.log('receive_uploader_cancel', {uploader_id, value, line, status, ...data});
        QB.alert = __("Processing cancelled");
    }

}
