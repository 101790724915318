import QBEditMediaAuditive from "./auditive";
// import {QBMedia} from "../../../../media";
import {TimeHelper} from "../../time";
import {QBAudio, QBMediaAudio} from "../audio";

export class ExtAudioReverse extends QBEditMediaAuditive {
    get target_indices() { return 1 }

    get name() { return __("Task::ExtAudioReverse") }
    get className() { return "task-ext_audio_reverse" }

    get select_options() { return [] }


    get primary() { return __('Song reverse') }
    get secondary() { return __('Song correct') }

    tools(media) {
        const details = super.tools(media);
        details.open = true;
        cDIV({append: details},
            cBUTTON(__("Reverse position - %{num}s", {num: 30}), { type: 'button',handle: ['click', this, true],  class: 'qbbutton', data: {action: 'reverse'}}),
            cBUTTON(__("Set end and cut"), { type: 'button', handle: ['click', this, true], class: 'qbbutton', data: {action: 'cut'}}),
            cBUTTON(__("Done! Save it!"), { type: 'button', handle: ['click', this, true], class: 'qbbutton', data: {action: 'save'}})
        )
        return details;
    }

    handleEvent(evt) {
        const action_target = evt.target.closest("[data-action]");
        if(action_target && 'action' in action_target.dataset) {
            switch (action_target.dataset.action) {
                case 'reverse':
                    return this.reverse(action_target);
                case 'cut':
                    return this.cut(action_target);
                case 'save':
                    return this.save(action_target);
            }
        }
        return super.handleEvent(evt);
    }

    get forwards_audio() { return this.section.querySelector('[data-attribute="media_auditive_1"] audio') }
    get backwards_audio() {
        const backwards_audio = this.section.querySelector('[data-attribute="media_auditive_0"] audio');
        if(backwards_audio) return backwards_audio;
        const media = QBMediaAudio.create({target: this.section, content_type: 'audio/wav', attribute: "media_auditive_0", index: 0, subindex: 0})
        return media.figure.firstElementChild;
    }

    get backwards_figure() { return this.backwards_audio.parentElement}

    get backwards_media() {
        return this.backwards_figure.media;
    }

    toHMS(seconds) {
        return new Date(seconds * 1000).toISOString().replace(/.*T(00:)?|\..*/g,'');
    }

    reverse(evt) {
        const forwards_audio = this.forwards_audio,
            backwards_audio = this.backwards_audio;

        if(forwards_audio.currentTime < 1) {
            forwards_audio.currentTime = forwards_audio.duration / 3 * 2
        }
        forwards_audio.pause();
        backwards_audio.pause();
        backwards_audio.currentTime = 0;
        backwards_audio.src = "";
        backwards_audio.onloadend = backwards_audio.ondurationchange = evt=>console.log(evt)
        const start = -30;
        QB.notice = __("Reversing %{duration}s from %{time}", {
            time: this.toHMS(Math.ceil(forwards_audio.currentTime) + start),
            duration: - start
        });

        new QBAudio(forwards_audio).cut({start: start, end: Math.ceil(forwards_audio.currentTime) }).reverse
            .to_url(url => {
                backwards_audio.src = url;
                backwards_audio.dataset.to = Math.ceil(forwards_audio.currentTime);
                backwards_audio.dataset.from = Math.ceil(forwards_audio.currentTime) + start;
                QB.notice = __("Done");
            });
    }

    cut(evt) {
        const forwards_audio = this.forwards_audio,
            backwards_audio = this.backwards_audio;

        backwards_audio.pause();
        if(backwards_audio.currentTime < 1) {
            forwards_audio.currentTime = 15
            return QB.alert = __("Position the end at the backwards clip (min 1s).");
        }

        QB.notice = __("Cutting at %{time}", { time: TimeHelper.toHMS(Math.ceil(backwards_audio.currentTime)) });

        new QBAudio(backwards_audio).cut({end: backwards_audio.currentTime})
            .to_url(url => {
                const from = parseInt(backwards_audio.dataset.from);
                backwards_audio.dataset.to = from + Math.ceil(backwards_audio.currentTime);
                backwards_audio.src = url;
                // backwards_audio.parentElement.dataset.id = src.replace(/.*\//, '');
                QB.notice = __("Done");
            });
    }

    save(evt) {
        const media = this.backwards_media;
        fetch(this.backwards_audio.src).then(r => r.blob()).then(file=>
            QB.Media.file_upload(file, media)
        );
    }

}