import {QBMediaBase} from "./base";
// import {QBMediaAudio} from "./audio";
export class QBMediaImage extends QBMediaBase {

    static render({src, ...data}) { return this.__proto__.render({tag: cIMG({src: src}), className: 'image', ...data})}


    static get mime_types() {
        return {
            "image/webp": ["webp"],
            "image/gif": ["gif"],
            "image/tiff": ["tiff tif"],
            "image/png": ["png apng"],
            "image/jpeg": ["jpeg jpg jpe"],
            "image/svg+xml": ["svg"]
        };
    }

    get tag(){ return 'img' }
    get type() { return 'media_visual' }


    get initEventListeners() {
        this.addEventListeners('click');
    }

    click(evt) {
        const fullscreen = evt.target.closest("[id].fullscreen");
        if(fullscreen && evt.target.matches('.close-fullscreen')) this.end_fullscreen;
        else if(!fullscreen && !this.figure.closest('.edit')) this.init_fullscreen;
    }


    scale(factor) {
        const img = this.element;
        factor = parseFloat(factor) * 100;
        if(!factor || factor < 100) {
            this.position(-50,-50);
            factor = 100;
        }
        else if(factor > 400) factor = 400;
        img.style.width = img.style.height = factor + '%';
        img.dataset.s = factor;
    }

    move({movementX, movementY, buttons}) {
        if(!buttons) return;
        const img = this.element;
        let {x, y, s} = img.dataset;
        if(parseFloat(s) == 100) x = y = -50;
        else {
            const f = -100;
            if (isNaN(x)) x = -50;
            x = parseFloat(x) + (movementX || 0) / 10;
            if (x < f) x = f;
            else if (x > 0) x = 0;

            if (isNaN(y)) y = -50;
            y = parseFloat(y) + (movementY || 0) / 10;
            if (y < f) y = f;
            else if (y > 0) y = 0;
        }
        this.position(x, y)
    }

    position(x, y) {
        const img = this.element;
        img.style.objectFit = 'contain';
        x = Math.floor(x * 10) / 10;
        y = Math.floor(y * 10) / 10;
        Object.assign(img.dataset, {x: x, y: y});
        img.style.transform = `translate(${x}%, ${y}%)`;
    }

    get close() {
        return this.container.querySelector('.close-fullscreen') ||
            cBUTTON('✖️', { type:'button', class: 'close-fullscreen', append: this.container, onclick: this.click.bind(this) })
    }

    get init_fullscreen() {
        console.log('xx');
        for(let a = this.container; a; a = a.parentElement) a.classList.add('fullscreen');
        this.container.closest(".task").dataset.focusIndex = this.figure.dataset.index;

        const media = Array.from(this.container.querySelectorAll('figure.image')).map(elem=>{
            const media = elem.media;
            media.position(-50,-50);
            media.scale(1);
            media.figure.onmousemove = e => media.move(e);
            media.figure.ondragstart = media.figure.ondrop = e => e.preventDefault();
            return media;
        });

        const slider = this.slider({value: 0, min: 1, max: 4, step: 0.1});
        slider.onmousemove = e => e.stopPropagation();
        slider.oninput = e => {
            media.forEach(media=>media.scale(e.target.value));
        }
        
        this.close;
    }

    get end_fullscreen() {
        Array.from(document.getElementsByClassName('fullscreen')).forEach(e=>e.classList.remove('fullscreen'));
        this.container.querySelectorAll('input[type="range"]').forEach(e=>e.remove());
        const media = Array.from(this.container.querySelectorAll('figure.image')).map(elem=> {
            const media = elem.media;
            media.figure.ondragstart = media.figure.ondrop = media.figure.onmousemove = null;
            media.element.style = '';
        });

        this.close.remove();
        QB.scrollIntoView(this.figure.closest('[id]'), false);
    }

}




