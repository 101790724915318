import {QBEditField} from "./base";
export class QBFieldTypeList extends QBEditField {
    static get match() { return 'input[list]'}
    constructor(field, edit) {
        super(field, edit, 'change');
    }


    handleEvent(evt) {
        console.log(evt);
        switch (evt.type) {
            case "change":
                this.put();
                return;
        }
    }

    get params() {
        return {
            content: this.element.value,
            checked: true
        }
    }

    receive_update({html}) {
        this.element.closest('[data-attribute]').replaceWith(cDIV({html: html}).firstElementChild);
    }
}