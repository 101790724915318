import QBEditMedia from "./base";
import {QBMediaImage} from "../image";
import {QBMediaVideo} from "../video";


export default class QBEditMediaVisual extends QBEditMedia {
    get type() { return 'visual' }
    get name() { return __('Visual media') }
    get attribute ()  { return 'media_visual' }
    get mime_types() {
        return Object.keys(Object.assign({}, QBMediaImage.mime_types, QBMediaVideo.mime_types));
    }

    get select_options() {
        return [[1,1],[1,2],[1,3],[2,2],[2,3],[2,4]].map(
            ([r,c])=>[
                n__("1 row", "%{r} rows", r, {r}) + " x " +
                n__("1 column", "%{c} columns", c, {c}),
                r * c
            ]
        ); //.concat([[__('Any number'), '+']]);
    }
}

