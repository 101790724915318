import {QBMediaBase} from "./base";

export class QBMediaPlaceholder extends QBMediaBase {
    static render({src, ...data}, target) {
        return cFIGURE({class: 'placeholder', dataset: data, append: target}, cFIGCAPTION());
    }


    get tag() { return 'figcaption'};
    get class_name() { return 'placeholder'};

    constructor(figure) {
        super(figure);
        const text1 = __('Drop or paste %{type}', { type: this.media_number ? __("secondary media") : __("primary media") }),
            text2 = this.media_number ? __("Solution") : __("Illustration or ask");
        cDIV({prepend: figure},
            cDIV({editable: true}, text1, document.createElement('br'), "(", text2, ")"),
            cLABEL(
                cDIV(__("Click to upload from your computer")),
                cINPUT({type: 'file', handle: ['change', this, true], accept:  Object.keys(this.mime_types).join(",")})
            )
        );
        this.edit_tools;
    }


    get edit_remove() { }
    get edit_append() {}

    
    get initEventListeners() {
        this.addEventListeners('click', 'keydown', 'blur', 'paste');
    }

    editHandleEvent(evt) {
        switch(evt.type) {
            case 'click':
                return window.getSelection().selectAllChildren(evt.target);
            case 'keydown':
                if(evt.key == "Escape") evt.target.blur();
                else if(evt.key !== 'v' || !evt.metaKey) evt.preventDefault();
                return;
            case 'change':
                return this.change(evt);
            case 'blur':
                return window.getSelection().removeAllRanges();
            case 'paste':
                return this.paste(evt);
            case 'drop':
                return this.drop(evt);
            default:
                return super.editHandleEvent(evt);
        }
        console.log(evt);
    }


    change(evt) {
        if(evt.target.files) this.files(evt.target.files);
    }

    paste(evt) {
        evt.preventDefault();
        if (evt.clipboardData.types.indexOf('text/plain') === -1) return;
        return QB.Media.url({
            target: this.element,
            url: evt.clipboardData.getData('text/plain'),
            ...this.data
        });
    }

    drop(evt) {
        const types = evt.dataTransfer.types;
        if (types.indexOf("Files") != -1) {
            const files = Array.from(evt.dataTransfer.files).filter(f => {
                if(this.mime_types.indexOf(f.type) >= 0) return true;
                QB.alert = __("File type not allowed: %{type}", {type: f.type});
            });
            if(files.length) this.files(files);
        }
        else if (types.indexOf("text/uri-list") != -1)
            QB.Media.url({
                target: this.element,
                url: evt.dataTransfer.getData('text/uri-list'),
                ...this.data
            });
        else this.edit_drop(evt);
    }

    files(files) {
        Array.from(files).forEach(file =>
            QB.Media.file({
                target: this.element,
                file: file,
                ...this.data
            })
        );
    }

    get mime_types() {
        if(!this._mime_types) this._mime_types = this.section.edit.mime_types;
        return this._mime_types;
    }

}
