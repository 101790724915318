
import {QBEdit} from "../edit";

export class QBMediaBase {
    static init(element, edit) {
        if(element.nodeName !== 'FIGURE') throw "element not a figure";
        if(!element.media) new this(element);
        return element.media;
    }

    static create({media, ...data}) {
        return new this(this.render(data));
    }


    static render({tag, target, className, ...data}) {
        if(target) {
            const t = target.closest('div[data-index]');
            // TODO target ist task hier eventuell ... 
            target = t ? t : target.querySelector(`div[data-index='${data.index}']`) || cDIV({ append: target, data: {index: data.index}});
        }
        return cFIGURE({class: className, dataset: data || {}, prepend: target}, tag, cFIGCAPTION())
    }

    constructor(figure) {
        this.figure = figure;
        this.figure.media = this;
        this.initEventListeners;
    }

    handleEvent(evt) {
        if(this.edit) return this.editHandleEvent(evt);
        else if (this[evt.type]) return this[evt.type](evt);
        console.log('handleEvent', evt);
    }

    canplay(evt) { this.element.controls = true; }
    canplayThrough(evt) { this.element.controls = true; }

    editHandleEvent(evt) {
        if (this[`edit_${evt.type}`]) return this[`edit_${evt.type}`](evt);
        console.log('editHandleEvent', evt);
    }

    get element() { return this.figure.querySelector(this.tag) }

    set src(src) {
        if(src === undefined) return;
        if(src === false) {
            this.end_fullscreen;
            this.figure.remove();
            return;
        }
        const current_src = this.src;
        if(src === current_src) return;
        const element = this.element.cloneNode(true);
        this._src = element.src = src;
        element.addEventListener(element.nodeName == 'IMG' ? 'load' : 'loadeddata', ()=>{
            this.element.replaceWith(element);
            delete this._src;
            if(/^blob:/.test(current_src)) URL.revokeObjectURL(current_src);
        }, { once: true })
    }


    get src() {
        if(this._src) return this._src;
        const media_elem = this.element;
        if(media_elem) return media_elem.src;
    }

    get fetch_content_type() {
        return fetch(this.src, { method: 'HEAD' }).then(response => this.data.content_type=response.headers.get('Content-type'));
    }

    get content_type() {
        return this.data.content_type
    }


    get data() { return this.figure.dataset }
    get object_id() { return this.data.objectId }
    get object() { return document.getElementById(this.object_id) }
    get index() { return parseInt(this.data.index) }
    get attribute() { return this.data.attribute }
    get type() { return this.attribute.split('_')[1]}

    get caption() {
        if(!this._caption) this._caption = this.figure.querySelector('figcaption') || cT('figcaption', {append: this.figure});
        return this._caption;
    }

    slider(data) {
        const slider = this.container.querySelector('input[type="range"]') || cINPUT({append: this.container, type: 'range'});
        if(data) {
            if (data.value !== undefined) slider.value = data.value;
            if (data.min !== undefined) slider.min = data.min;
            if (data.max !== undefined) slider.max = data.max;
            if (data.step !== undefined) slider.step = data.step;
        }
        return slider;
    }


    progress(value, status,  ...line) {
        let container = this.caption.querySelector('.progress')
        if(value == 'finished') {
            if(container) container.remove();
            return;
        }
        if(!container) container = cDIV({class: 'progress', append: this.caption}, cT('progress'), cDIV());
        const [progress, title] = container.children;
        if(isNaN(value)) {
            if (value === undefined) progress.removeAttribute('value');
        }
        else {
            value = parseFloat(value);
            if(isNaN(value)) {
                progress.removeAttribute('value');
                // debugger;
                // line.push(__("Failure in value"));
            }
            else {
                progress.value = value;
                const max = progress.max = Math.ceil(value);
                line.push(__("Pass %{num}", {num: max}));
                line.push(Math.floor((value - Math.floor(value)) * 100) + "%");
            }
        }

        title.textContent = line.filter(l=>l).join(", ");
        this.figure.dataset.status = status;
    }

    get container() { return this.figure.closest('.media') }

    swap_with({attribute, index, subindex}) {
        const container = this.container,
            to_div = container.querySelector(`div[data-index="$.{index}"]`) || cDIV({data: {index: index}, append: container}),
            replace = to_div.querySelector(`figure:not(.placeholder)[data-attribute="${attribute}"]`);
        console.log(this.data, {attribute, index, subindex}, replace )
        if(replace) {
            const p = cDIV();
            this.figure.replaceWith(p);
            replace.replaceWith(this.figure);
            p.replaceWith(replace);
            Object.assign(replace.dataset,  this.data);
        }
        else {
            if(container.edit && !to_div.children) to_div.append(...container.edit.constructor.placeholders(this.type, i));
            to_div.prepend(this.figure)
        }
        Object.assign(this.data, { attribute, index, subindex });
    }

    get edit() { return this._edit }

    get section () { return this.figure.closest('section.media') }
    set edit(on) {
        this._edit = on = !!on;
        this.caption.textContent = '';
        if (on) {
            this.section.edit.tools(this);
            const append = this.edit_append;
            if(append) this.caption.append(append);
            this.addEventListeners('dragstart', 'dragend', 'dragover', 'drop');
        }
        else {
            this.removeEventListeners('dragstart', 'dragend', 'dragover', 'drop');
        }
    }

    addEventListeners(...events) {
        events.forEach(event=>this.figure.addEventListener(event, this, true));
    }

    removeEventListeners(...events) {
        events.forEach(event=>this.figure.removeEventListener(event, this, true));
    }

    get edit_remove() { return cBUTTON({ type: 'button', data: { action: 'remove' }, handle: ['click', this, false]},'♻️') }
    get edit_append() { return cDIV({data: {action: 'drag'}, draggable: true, handle: ['dragstart', this, false]},'☰') }

    get drag_payload() { return Object.assign({}, this.data) }
    drop_payload(evt) {
        const data = evt.dataTransfer.getData('text/plain');
        try {
            return data && JSON.parse(data);
        } catch(e) {
            console.error('not parsable', data);
        }
    }


    put(args) {
        return this.section.edit.put(Object.assign({}, this.data, args));
    }
    
    edit_dragstart(evt) {
        evt.dataTransfer.setData('text/plain', JSON.stringify(this.drag_payload));
        evt.dataTransfer.effectAllowed = "move";
        const {attribute, index, subindex} = this.data,
            elems = this.section.querySelectorAll(`figure:not([data-attribute="${attribute}"][data-index="${index}"][data-subindex="${subindex}"])`);
        elems.forEach(e=>e.classList.add('drag-target'));
    }

    edit_dragend(evt) {
        Array.from(document.getElementsByClassName('drag-target')).forEach(e=>e.classList.remove('drag-target'));
    }

    edit_dragover(evt) {
        if(this.drag_payload == this.drop_payload(evt)) return;
        evt.preventDefault();
    }

    edit_drop(evt) {
        const drop_payload = this.drop_payload(evt)
        if(!drop_payload) return;
        this.put({attribute: "media_swap", from: drop_payload, to: this.drag_payload});
    }

    get upload() {
        const { promise, reject, resolve } = Promise.create();
        if(/^blob:/.test(this.src)) fetch(this.src).then(r=>resolve(r.blob()));
        else resolve(this.src || this.content);
        promise.then(src=>this.put({ src: src, ...this.data }));
        return promise;
    }

    get checksum() { return this.data.checksum }

    set checksum(checksum) {
        this.data.checksum = checksum;
    }

    edit_click(evt) {
        console.log(evt);
        const action_target = evt.target.closest('[data-action]');
        switch(action_target && action_target.dataset.action) {
            case 'remove':
                this.put({src: false});
                break;
        }
    }


    update({...data}) {
        console.log('media updater', data)
        if('updater' in data) this.updater = data.updater;
        if('checksum' in data) this.checksum = data.checksum;
        if('src' in data) this.src = data.src;
    }

    set updater(updater) {
        if(updater) this.data.updater = updater;
        else delete this.data.updater;
    }

    set checksum(checksum) {
        if(checksum) this.data.checksum = checksum;
        else delete this.data.checksum;
    }
}

export class QBMediaPlayable {
    get initEventListeners() {
        console.log('hier')
        this.element.addEventListener('canplay', this, true);
    }
}