import {QBEditField} from "./base";
class QBFieldTypeChoice extends QBEditField {
    constructor(field, edit) {
        super(field, edit, 'change');
    }

    handleEvent(evt) {
        console.log('handle event', evt, this.element.checked);
        switch (evt.type) {
            case "change":
                evt.stopImmediatePropagation();
                this.put();
                return;
        }
    }

    onclick(evt) {
        console.log('click', evt);
    }

    get params() {
        return {
            index: this.element.value,
            content: this.element.value,
            checked: this.element.checked
        }
    }

    receive_update({checked, content, ...data}) {
        console.log(this.element, content, checked);
        this.element.checked = checked;
    }
}

export class QBFieldTypeCheckbox extends QBFieldTypeChoice {
    static get match() { return 'input[type="checkbox"]:not(.list)' }
}

export class QBFieldTypeRadio extends QBFieldTypeChoice {
    static get match() { return 'input[type="radio"]' }
}

export class QBFieldTypeListEntry extends QBFieldTypeChoice {
    static get match() { return 'input[type="checkbox"].list' }
    constructor(field, edit) {
        super(field, edit, 'change');
        console.log(this.constructor.name);
    }

    get params() {
        return {
            content: this.element.value,
            checked: this.element.checked
        }
    }

    receive_update({checked, content, ...data}) {
        console.log(this.element, content, checked, data);
        if(checked) return;
        this.element.parentElement.remove();
        this.destructor();
    }
}