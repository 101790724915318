import {QBMediaBase, QBMediaPlayable} from "./base";

export class QBMediaVideo extends QBMediaBase {
    static render({src, ...data}) { return this.__proto__.render({tag: cVIDEO({src: src, controls: true}), className: 'video', ...data})}

    static get mime_types() {
        return {
            "video/mpeg": ["mpg mpeg mpe"],
            "video/mp4": ["mp4"],
            "video/webm": ["webm"]
        };
    }

    get tag(){ return 'video' }
    get type() { return 'visual' }

    set poster(poster) {
        this.element.poster = poster;
    }

}
Object.assign(QBMediaVideo, QBMediaPlayable);
