import {QBEditField} from "./base";
export class QBFieldTypeQBTitle extends QBEditField {
    static get match() { return '[data-qbtitle]' }
    constructor(field, edit) {
        super(field, edit, 'input');
    }

    onclick(evt) {
        if(evt) evt.preventDefault();
        const parent = this.element.parentElement,
            nodes = Array.from(parent.querySelectorAll('[data-qbtitle]')),
            texts = nodes.map(e=>[e.dataset.qbtitle, cT('br')]).flat();
        texts.pop();
        nodes.filter(n=>n!==this.element).forEach(n=>n.remove());
        this.element.textContent='';
        this.element.append(...texts);
        this.element.contentEditable = 'true';
        const label = this.element.closest('label');
        if(label) label.control.disabled = true;
        this.element.focus();
    }
    focus() { this.onclick(); }

    onblur(evt) {
        this.element.removeAttribute('contenteditable');
        const label = this.element.closest('label');
        if(label) label.control.disabled = false;

        const nodes = [],
            contents = [];
        this.element.childNodes.forEach(c=>{
            if(c.nodeType == 3) {
                nodes.push(cSPAN({data: {qbtitle: c.textContent}}));
                contents.push(c.textContent);
            }
        });
        const parent = this.element.parentElement;
        parent.textContent="";
        parent.append(...nodes);
        this.element = parent.firstChild;
        if(this.changed) this.put({ content: contents.join("\n")});
        super.onblur(evt);
        this.changed = false;
    }


    handleEvent(evt) {
        // console.log(evt);
        switch (evt.type) {
            case "blur":
                this.onblur();
                return;
            case "input":
                return this.oninput(evt);
        }
    }

}