import {QBEditField} from "./base";

export class QBFieldTypePaste extends QBEditField {
    static get match() {return '.field-paste'}

    constructor(element, edit) {
        super(element, edit, 'click', 'keydown', 'paste', 'blur', 'dragover', 'drop');
        element.contentEditable = true;
    }

    destructor() {
        this.element.contentEditable = false;
    }

    paste(evt) {
        evt.preventDefault();
        if (evt.clipboardData.types.indexOf('text/plain') === -1) return;
        const pasted = evt.clipboardData.getData('text/plain');
        if(!QB.UUID_RE.test(pasted)) return QB.alert = __("Not an id");
        QB.notice = __("Pasted %{id}", {id: pasted});
        this.put({ reference_id: pasted });
    }

    click(evt) { window.getSelection().selectAllChildren(evt.target); }
    keydown(evt) {
        if (evt.key == "Escape") evt.target.blur();
        else if (evt.key !== 'v' || !evt.metaKey) evt.preventDefault();
    }
    blur(evt) { window.getSelection().removeAllRanges() }

    dragover(evt) {
        console.log(this.constructor.name, 'dragover')
    }

    drop(evt) {
        console.log(this.constructor.name, 'drop')
    }

    // receive_update({html}) {
    //
    //     // this.element.closest('[data-attribute]').replaceWith(cDIV({html: html}).firstElementChild);
    // }
}