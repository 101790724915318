import './js_extensions'


window.cT = function (tag, ...options) {
    const elem = document.createElement(tag);
    options.filter(o=>o).forEach(option => {
        switch (Object.type(option)) {
            case 'Object':
                Object.entries(option).forEach(cT.entries.bind(elem));
                break;
            case 'Array':
            case 'NodeList':
                elem.append(...option);
                break;
            case 'Undefined':
            case 'Null':
                break;
            case 'String':
                elem[/(<[^<]+>)|(&#x?\d+;)|(&[a-z]+;)/i.test(option) ? 'insertAdjacentHTML' : 'insertAdjacentText']('beforeend', option);
                break;
            default:
                elem.append(option);
        }
    });
    return elem;
}

window.cT.entries = function ([key, value]) {
    if(value === undefined || value === null) return;
    if (typeof value == 'function') {
        if(value.prototype === undefined) this[key] = value;
        else this[key] = value.bind(this);
        return;
    }
    switch(key) {
        case 'data':
        case 'dataset':
            return Object.assign(this.dataset, Object.compact(value));
        case 'display':
            if (value === false) this.style.display = 'none';
            else if (value !== true) this.style.display = value;
            return;
        case 'append': return value.append(this);
        case 'prepend': return value.prepend(this);
        case 'before': return value.before(this);
        case 'after': return value.after(this);
        case 'addEventListener':
        case 'handle':
            return this.addEventListener(...value);
        case 'contentEditable':
        case 'contenteditable':
        case 'editable':
            return this.contentEditable = value;
        case 'checked': return this.checked = value;
        case 'selected': return this.selected = value;
        case 'html': return this.innerHTML = value;
        case 'className':
        case 'class': return this.setAttribute('class', value);
        case 'for': case 'htmlFor': return this.htmlFor = value;
        default: if(value != undefined && value != null) this.setAttribute(key, value);
    }
}

window.cT.media_converter = source => console.log('media_converter', source);



Object.assign(window, Object.fromEntries(
    'div li span ul ol menu details summary button input a style q blockquote progress label img audio video figure figcaption option header br p'
        .split(' ').map(tag=>[`c${tag.toUpperCase()}`, window.cT.bind(window.cT, tag)])
));


window.cTextField = (name, value, ...options) => window.cT('input', { type: 'text', value: value, name: name }, ...options);

window.cCheckRadio = (type, name, value, checked, option, ...options) => {
  if(Object.type(option) != 'Object') {
      options.unshift(option);
      option = undefined;
  }
  const id = name.replace(/[\[\]]+/g, '_');
  const input = window.cT('input', { type: type, value: value, name: name, checked: checked, id: id }, option);
  return options.length == 0 ? input : cLABEL({for: id}, input, ...options);
};

window.cCheckBox = (...options) => window.cCheckRadio('checkbox', ...options);
window.cRadioButton = (...options) => window.cCheckRadio('radio', ...options);
window.cHiddenField = (name, value, ...options) => window.cT('input', { type: 'hidden', value: value, name: name }, ...options);

window.cSelect = (name, option, ...options) => {
    let value;
    if(Object.type(option) != 'Object') {
        options.unshift(option);
        option = undefined;
    }
    else {
        value = option.value;
        delete option.value;
    }

    const id = name.replace(/[\[\]]+/g, '_');
    return window.cT('select', {name: name, id: id}, option, ...options.map(o=>{
        if(o.nodeName) return o;
        if(typeof o == 'string') o = [o, o];
        return cT('option', {value: o[1], selected: o[1] === value}, o[0]);
    }));
}


class Fragment {
    constructor(root_text, ...nodes) {
        this.frag = document.createDocumentFragment();
        const d = document.createElement('div');
        nodes.forEach(node => {
            switch (Object.type(node)) {
                case 'String':
                    d.innerHTML = node;
                    if(root_text) this.frag.append(...d.childNodes);
                    else this.frag.append(...d.children);
                    break;
                case 'Array':
                case 'NodeList':
                    this.frag.append(...node);
                    break;
                case 'Undefined':
                case 'Null':
                    break;
                default:
                    this.frag.append(node);
            }
        });
    }
    get children() { return Array.from(this.frag.children) }

    forEach(...args) { this.children.forEach(...args) }

    update(target, {select, update, append}) {
        if(!select) select = child=>`#${child.id}`;
        if(!update) update = (container,child)=>container.innerHTML = child.innerHTML;
        const containers = Array.from(target.children)
            .filter(container=>container == target.querySelector(select(container)));
        this.forEach(child=>{
            const container = target.querySelector(select(child));
            if(container) {
                const i = containers.indexOf(container);
                if(i>-1) containers.splice(i, 1);
                update(container,child);
            }
            else if(append) append(child);
            else target.append(child);
        });
        containers.forEach(container=>container.remove());
    }
}

window.Fragment = function(...nodes) { return new Fragment(false, ...nodes) };
window.FragmentText = function(...nodes) { return new Fragment(true, ...nodes) };


window.meta_content = (name, fallback) => {
    const el = document.querySelector(`meta[name="${name}"]`);
    return el ? el.getAttribute('content') : fallback;
}
window.set_meta = (name, value) => {
    const el = document.querySelector(`meta[name="${name}"]`) || cT('meta', { name: name, append: document.head});
    el.setAttribute('content', value);
}