import {QBEditField} from "./base";
import "../trix";

class QBFieldTypeTextBase extends QBEditField {
    constructor(field, edit, ...events) {
        super(field, edit, ...events);
    }

    onblur(evt) {
        this.ontextblur(evt);
        super.onblur(evt);
    };

    get params() {
        return { content: this.element.value }
    }
}

export class QBFieldTypeText extends QBFieldTypeTextBase {
    static get match() {return 'input[type="text"]'}

    constructor(field, edit) {
        super(field, edit, 'input');
    }

    handleEvent(evt) {
        switch(evt.type) {
            case "focus":
                return this.onfocus(evt);
            case "input":
                return this.oninput(evt);
            case "blur":
                return this.ontextblur(evt);
        }
        super.handleEvent(evt);
    }
}

export class QBFieldTypeTrix extends QBFieldTypeTextBase {
    static get match() { return 'trix-editor' }

    constructor(field, edit) {
        super(field, edit, 'trix-change');
    }

    handleEvent(evt) {
        switch(evt.type) {
            case "trix-focus":
                return this.onfocus(evt);
            case "trix-change":
                return this.oninput(evt);
            case "trix-blur":
                return this.ontextblur(evt);
        }
        super.handleEvent(evt);
    }
}

export class QBFieldTypeNumber extends QBFieldTypeText {
    static get match() {return 'input[type="number"]'}
}