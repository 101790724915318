import {QBEdit} from "../../edit";

import {QBMediaPlaceholder} from '../placeholder';

export default class QBEditMedia {
    static search() {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
        const task = event.target.closest(".task"),
            solutions = Array.from(task.querySelectorAll('.response .solution')).map(s=>s.textContent).filter(s=>!/^\s*$/.test(s));
        if(!solutions.length) return;
        // todo refactor to server: make a search there first, then redirect to YT
        if(/\b(task-ext_music)\b/.test(task.className)) {
            const url = new URL('https://www.youtube.com/results');
            url.search = new URLSearchParams({search_query: solutions.join(' ')})
            console.log(url.toString())
            window.open(url, 'youtube', 'popup=1');
        }
    }

    get className() { return '' }

    constructor(media) {
        this.target = (media.element ? media.element : media).closest('aside.media');
        if(!this.target) throw "not a media container";

        document.querySelectorAll(`.overlay[data-id]`).forEach(o=>o.remove());

        let label;
        QB.overlay({ class: `left full media ${this.type} ${this.className}`, data: { id: this.id } },
            this.container = cDIV(
                cDIV(label = cLABEL(cSPAN(this.name))),
                this.section = cT('section', { class: `media ${this.type} media-edit`})
            ), this.close.bind(this)
        );
        this.select = cSelect('indices', { append: (this.select_options.length ? label : null), handle: ['change', this, true] }, [__("No media"),0], ...this.select_options);

        this.section.append(...Array.from(this.target.children).map(c=>{
            const node = c.cloneNode(true);
            node.append(...this.constructor.placeholders(this.type, node.dataset.index));
            return node;
        }));
        this.section.edit = this;
        this.indices = this.target_indices;
    }

    static placeholders(type, i) {
        return [
            QBMediaPlaceholder.render({index: i, attribute: `media_${type}_0`, subindex: 0}),
            QBMediaPlaceholder.render({index: i, attribute: `media_${type}_1`, subindex: 1})
        ];
    }



    get id() {
        return this.target.closest('[id]').id;

    }
    get primary() { return __('Primary') }
    get secondary() { return __('Secondary') }

    tools(media) {
        return cDETAILS({append: media.caption, class: 'edit-tools'},
            cSUMMARY(
                cSPAN({class: 'media-primary'}, this.primary),
                cSPAN({class: 'media-secondary'}, this.secondary)
            ),
            media.edit_remove
        )
    }

    handleEvent(evt) {
        if(this[evt.type]) this[evt.type](evt);
        else return console.log(evt);
        evt.stopPropagation();
    }


    change(evt) {
        evt.stopImmediatePropagation();
        this.put({ attribute: this.attribute, indices: evt.target.value });
    }


    put(...args) {
        QBEdit.get(this.target).then(edit=>edit.put(...args));
    }

    close(elem) {
        console.log('closed media edit', elem);
    }

    get target_indices() { return this.target.dataset.indices; }

    set indices(indices) {
        indices = parseInt(indices) || 0;
        const ist = Math.max(this.section.children.length,this.target.children.length);
        this.select.value = indices = this.target.dataset.indices = this.section.dataset.indices = indices;
        if(indices === ist) return;
        if(ist > indices) {
            for(let i = indices; i < ist; i++) {
                const s = this.section.children[i],
                    t = this.target.children[i];
                if(s) s.remove();
                if(t) t.remove();
            }
        }
        else {
            for(let i = ist; i < indices; i++) {
                if(!this.section.children[i]) cDIV({data: {index: i}, append: this.section}, ...this.constructor.placeholders(this.type, i));
                if(this.target.children[i]) cDIV({data: {index: i}, append: this.target});
            }
        }
    }

}



