// import { QBEditEvent } from "./edit/event";
// import { QBEditTask } from "./edit/task";

import {QBChannel} from "./channel";
import {QBAttribute} from "./attribute";
import {QBMedia} from "./media";
import {QBFieldTypeCheckbox, QBFieldTypeListEntry, QBFieldTypeRadio} from "./edit_field/choice";
import {QBFieldTypeNumber, QBFieldTypeText, QBFieldTypeTrix} from "./edit_field/text";
import {QBFieldTypeContenteditable} from "./edit_field/contenteditable";
import {QBFieldTypeList} from "./edit_field/list";
import {QBFieldTypeQBTitle} from "./edit_field/qbtitle";
import {QBFieldTypePaste} from "./edit_field/paste";
import {QBFieldTypeButton} from "./edit_field/button";


export class QBEdit {
    static get init() {
        QB.Edit = QBEdit;
        this.channel = new QBChannel('CreatorChannel', this);
    }

    static get params() {
        const params = {},
            search = new URLSearchParams(document.location.search),
            hashtag = (search.get('s') || '').match(/#\S+/);
        if(hashtag) params.hashtag = hashtag[0];
        return params;
    }


    static create(path, button) {
        QB.fetch(path, {method: 'post', params: Object.assign(this.params, { type: button.value })}).then(({html})=>{
            const elem = button.closest('details');
            elem.insertAdjacentHTML('beforebegin', html);
            this.toggle(elem.previousElementSibling);
            elem.previousElementSibling.insertAdjacentHTML('beforebegin', elem.outerHTML);

            document.querySelectorAll(".QB-New").forEach(e=>e.open = false);
        });
    }

    static toggle(element) {
        element = this.element(element);
        // console.log(element);
        if(element.edit) element.edit.toggle;
        else this.get(element).then(e=>e.toggle);
    }

    static dblclick(element) {
        element = this.element(element);
        const m = e => {
            e.on;
            const sel = window.getSelection();
            sel.collapseToEnd();
            setTimeout(()=> {
                const target = sel.anchorNode.parentElement.closest(QBEdit.field_match);
                if(target) target.focus();
            }, 1)
        };
        if(element.edit) m(element.edit);
        else this.get(element).then(m);
    }

    static on(element) {
        element = this.element(element);
        const m = e=>e.on;
        console.log(event);
        if(element.edit) m(element.edit);
        else this.get(element).then(m);
    }

    static element(element) {
        if(!element) element = event;
        if(element.target) element = element.target;
        element = element.closest('[data-edit]');
        return element;
    }

    static get(element) {
        element = this.element(element);
        const { promise, reject, resolve } = Promise.create();
        if(!element) reject();
        else if(element.edit) resolve(element.edit);
        else if(element.dataset.edit === "task") resolve(new QBEditTask(element));
        else if(element.dataset.edit === "event") resolve(new QBEditEvent(element));
        else reject(module);
        return promise;
    }


    static get field_type_entries() {
        if(!this._field_type_entries)
            this._field_type_entries = [
                QBFieldTypeCheckbox, QBFieldTypeRadio, QBFieldTypeQBTitle,
                QBFieldTypeContenteditable, QBFieldTypeText, QBFieldTypeList,
                QBFieldTypeNumber, QBFieldTypeTrix, QBFieldTypeListEntry,
                QBFieldTypePaste //, QBFieldTypeButton
            ].map(k=>[`[data-attribute] ${k.match}, ${k.match}[data-attribute]`, k]);
        return this._field_type_entries;
    }
    static get field_types() {
        return Object.fromEntries(this.field_type_entries);
    }
    static get field_match() { return Object.keys(this.field_types).join(',') }

    set_field (field_element) {
        // console.log(field_element, QBEdit.field_match);
        field_element = field_element.closest(QBEdit.field_match);
        if(!field_element) return;
        if(this.field  && this.field.element === field_element) return this.field;
        const [match, klass] = QBEdit.field_type_entries.find(([m]) => field_element.matches(m));
        if(klass) {
            this.field = new klass(field_element, this);
        }
        else {
            delete this.field_element;
            this.field = undefined;
        }
        return this.field;
    }

    static copy_id(id) {
        QB.clipboard = id;
        if(!event) return
        event.preventDefault();
        if(!event.shiftKey) return;
        this.get(event).then(edit=>window.open( edit.path({id: id}), edit.base_path, 'popup=1'));
    }


    constructor(elem) {
        this.element = elem;
        elem.edit = this;
    }

    destructor() {
        this.off;
        delete this.element.edit;
        delete this.element;
    }

    get toggle() {
        if(this.element.classList.contains('edit')) this.off;
        else this.on;
    }

    get on() {
        this.element.classList.add('edit');
        this.element.addEventListener('focusin', this, true);
        this.element.addEventListener('focusout', this, true);
        this.element.addEventListener('click', this, true);
        // this.attributes = [];
        // this.element.querySelectorAll('[data-attribute]:not(figure, [data-attribute^="reference"])').forEach(f=>{
        //     this.attributes.push(new QBAttribute(this, f));
        // });
    }

    get off() {
        this.element.classList.remove('edit');
        this.element.removeEventListener('focusin', this, true);
        this.element.removeEventListener('focusout', this, true);
        this.element.removeEventListener('click', this, true);

        /*if(this.attributes) this.attributes.forEach(f=>f.destructor());
        delete this.attributes;*/
    }

    onfocusin(evt) {
        this.set_field(evt.target);
        // console.log('focusin', document.activeElement);
        if(this.field /*&& !this.field.hasFocus*/) this.field.onfocus(evt);
    }

    onfocusout(evt) {
        if(this.field && this.field.element === evt.target) this.field.onblur(evt);
    }

    onclick(evt) {
        const target = evt.target,
            action_target = target.closest("[data-action]");
        let media;
        if(action_target && 'action' in action_target.dataset) {
            switch (action_target.dataset.action) {
                case 'add':
                    return this.add(action_target);
                case 'remove':
                    return this.remove(action_target);
                case 'destroy':
                    return this.destroy(action_target);
                case 'media_visual-edit':
                case 'media_auditive-edit':
                    return QBMedia.edit(action_target);
                default: console.alert('edit onclick', action_target.dataset.action, action_target);
            }
        }
        else if(media = target.closest('aside.media')) {
            // if (media.classList.contains('visual')) return QBEditMediaVisual.show(this);
            // else if (media.classList.contains('audio')) return QBEditMediaAuditive.show(this);
        }
        else {
            this.set_field(target);
            if (this.field && !this.field.hasFocus) this.field.onclick(evt);
        }

    }

    add(element) {
        const attribute = new QBAttribute(element, this);
        attribute.put({ add: true, ...attribute.params});
    }

    remove(element) {
        const attribute = new QBAttribute(element, this);
        attribute.put({ remove: true, ...attribute.params});
    }

    destroy() {
        this.element.classList.add('to-be-deleted-alert');
        this.element.scrollIntoView({block: 'start'});
        setTimeout(()=> {
            QB.confirm(__("Do you want to delete the marked item?"), {
                title: __("Really delete?")
            }).then(answer => {
                if(!answer) return;
                QB.fetch(this.path(), { method: 'DELETE' }).then(() => {
                    if (document.location.pathname == this.path()) document.location = this.index_path;
                    else this.element.remove();
                });
            }).finally(() => this.element.classList.remove('to-be-deleted-alert'))
        }, 2000);
    }


    handleEvent(evt) {
        switch(evt.type) {
            case 'focusin':
                return this.onfocusin(evt);
            case 'focusout':
                return this.onfocusout(evt);
            case 'click':
                return this.onclick(evt);

        }
    }

    get id() { return this.element.id }

    get index_path() { return this.path({id: ''})}

    path(...extend) {
        if(extend[0] && extend[0].id !== undefined) extend[0] = extend[0].id;
        else if(this.id) extend.unshift(this.id);
        const root = location.pathname.replace(new RegExp(this.base_path + '(/.*)?$'), '')
        return [root, this.base_path, extend || ""].flat().join('/').replace(/\/+/g, '/');
    }

    get parent() {
        return QBEdit.get(this.element.parentElement);
    }

    put (params, prepend) {
        const { promise, reject, resolve } = Promise.create();
        this.parent.then(p=>{
            p.put(params, this.path(), resolve, reject).then(resolve).catch(reject);
        }).catch(()=> {
            const type = Object.type(params.src);
            if(params.src && (type == 'File' || type == 'Blob')) params = { formdata: params }
            else params = { params: params }
            QB.fetch(this.path(), { method: 'PUT', app_id: QB.app_id, ...params }).then(resolve).catch(reject);
        });
        return promise;
    }


    static receive_update({id, ...data}) {
        const element = document.getElementById(id);
        if(!element) return;
        this.get(element).then(edit=>edit.receive_update(data));
    }

    set errors(errors) {
        const err_element = this.element.querySelector('.error-messages ul');
        err_element.textContent = '';
        if(!errors || Object.keys(errors).length == 0) return this.element.classList.remove('errors');
        err_element.append(...Object.entries(errors).map(([k,v])=>cLI({ title: k}, v)));
    }

    receive_update({attribute, html, index, lang, errors, ...data}) {
        console.log("Edit#receive_update", {attribute, html, index, lang, errors, ...data});
        const div = cDIV({html: html}),
        content = div.firstElementChild;
        if(!attribute || (content && content.classList.contains('editor'))) return this.element.replaceWith(content);
        this.errors = errors;
        if(/^media_/.test(attribute)) return this.media_update({attribute, index, ...data});

        // TODO send to classes on receive
        const asel = `[data-attribute="${attribute}"]`,
            isel = `[data-index="${index}"]`;
        let attribute_element = this.element.querySelector(`${asel} ${isel}, ${asel}${isel}, ${isel} ${asel}`);
        if (attribute_element) {
            if(/^(reference_|solution)/.test(attribute) && html == ' ') return attribute_element.remove();
            if (!this.set_field(attribute_element)) return;
            this.field.receive_update({attribute: attribute, html, index, lang, attribute_element, ...data});
        }
        else {
            attribute_element = this.element.querySelector(asel);
            if(!attribute_element) return;
            if(!content) return;
            else if(div.children.length > 1 || (content.dataset.attribute !== attribute)) attribute_element.innerHTML = html;
            else attribute_element.replaceWith(content);
        }

    }

    media_update(data) { QBMedia.update({id: this.id, ...data}); }



    static get receive_uploader_started() { return QBMedia.receive_uploader_started; }
    static get receive_uploader_finished() { return QBMedia.receive_uploader_finished; }
    static get receive_uploader_progress() { return QBMedia.receive_uploader_progress; }
    static get receive_uploader_failed() { return QBMedia.receive_uploader_failed; }

}

class QBEditEvent extends QBEdit {
    constructor(element) {
        super(element);
        this.base_path = 'events';
    }
}

class QBEditTask extends QBEdit {
    constructor(element) {
        super(element);
        this.base_path = 'tasks';
    }
}