// import  { QBObserver } from "./observer";
// import { format } from 'date-fns-tz';   // https://date-fns.org // zonedTimeToUtc, utcToZonedTime,
// import { getTimeZones, rawTimeZones, timeZonesNames } from "@vvo/tzdb";   // https://github.com/vvo/tzdb/

import { utcToZonedTime } from 'date-fns-tz';

export class TimeHelper {
    static get init() {
        document.querySelectorAll('time').forEach(time => {
            if (!time.helper) new TimeHelper(time);
        });



        // if(!TimeHelper.initialized)
        //     TimeHelper.initialized = setInterval(()=>{
        //         document.querySelectorAll('time').forEach(time => {
        //             if(!time.helper) new TimeHelper(time);
        //             time.helper.tick;
        //         });
        //     }, 1000);
    }

    static zeroPad(nNum, nPad) {
        return ((Math.pow(10, nPad) + nNum) + '').slice(1);
    }

    static toHMS(seconds) {
        return new Date(seconds * 1000).toISOString().replace(/.*T(00:)?|\..*/g,'');
    }

    constructor(elem) {
        this.elem = elem;
        this.time_elements = {};
        elem.querySelectorAll('.time > *').forEach(e=>this.time_elements[e.className] = e);
        this.duration_elements = {};
        elem.querySelectorAll('.duration > *').forEach(e=>this.duration_elements[e.className] = e);
        elem.helper = this;
        elem.stop = this.stop.bind(this);
        elem.start = this.start.bind(this);
        elem.seconds = ()=>this.seconds;

        if (/^PT/.test(elem.dateTime)) {
            const [_, over,_d, d, _h, h, _m, m, _s, s] = this.elem.dateTime.match(/^PT(-)?((\d)+D)?((\d+)H)?((\d+)M)?((\d+)S)?/),
                secs = (parseInt(d) || 0) * 86400 + (parseInt(h) || 0) * 3600 + (parseInt(m) || 0) * 60 + (parseInt(s) || 0);
            elem.dataset.duration = elem.dateTime;
            elem.dateTime = new Date(new Date() * 1 + secs * (over ? -1000 : 1000)).toISOString();
        }
        if (elem.dataset.ontick) {
            this.ontick = new Function(elem.dataset.ontick).bind(this);
        }

        this.init;
    }

    get init() {
        if(this.handle) clearInterval(this.handle);
        this.updated_at = new Date() * 1;
        if(!this.disabled) this.handle = setInterval(()=>this.tick, 1000);
        this.elem.classList.add('init');
    }

    set ontick(ontick) {
        this._ontick = ontick;
    }

    get tick() {
        const cl = this.elem.classList;
        if(cl.contains('stop')) this.skip;
        else {
            if(this._ontick && !(cl.contains('over') && cl.contains('zero'))) {
                const rv = this._ontick();
                if(!isNaN(rv)) this.seconds = rv;
            }
            this.duration_update;
        }
        this.updated_at = new Date() * 1;
    }

    get dateTime() {
        return  this.elem.dateTime;
    }

    get basis() {
        const [y,m,...date] = this.dateTime.split(/[T\-\: \.\+Z]/, 7);
        return Date.UTC(y, parseInt(m) - 1, ...date);
    }

    get now() {
        return new Date() * 1 + this.zoneOffset * 1000;
    }

    adjust(s) {
        this.set_time(this.basis + s * 1000);
    }

    set seconds(s) {
        this.set_time(this.now + s * 1000);
        this.elem.classList[s > 0 ? 'remove' : 'add']('over');
        this.init;
        this.duration_update;
    }

    get seconds() {
        const seconds = (new Date() * 1 - Date.parse(this.elem.dateTime)) / 1000;
        // if(this.elem.id == "event-timer") console.log('seconds',this.elem.dateTime, seconds)
        return seconds;
    }

    set_time(epoch) {
        const timestr = new Date(epoch).toISOString().substr(0,23);
        this.elem.dateTime = timestr + this.zone;
        const [d, t] = timestr.split(/T|\./);
        this.elem.dataset.zonetime = `${d} ${t} ${this.timezone} ${this.zone}`;
        this.elem.dataset.duration = this.to_duration(this.duration);
        this.time_update;
    }

    stop() {
        if(!this.updated_at) this.updated_at = new Date();
        this.elem.classList.add('stop')
    }

    start() {
        this.elem.classList.remove('stop');
        this.skip;
        this.init;
    }

    get skip() {
        this.adjust((new Date() * 1 - this.updated_at) / 1000);
    }

    // get time() {
    //     return new Date(Date.parse(this.elem.dateTime));
    // }

    // get time() {
    //         const iso = utcToZonedTime(this.utc, this.timezone).toISOString().
    //         utcToZonedTime(this.utc, this.timezone).toISOString().replace(/T|(\.\d+)?Z/g, ' ');
    // }


    get time_update() {
        // console.log(this.dateTime);
    }

    get duration() {
        let secs;
        const duration = { total: secs = Math.floor(this.seconds) };
        secs = Math.abs(secs);
        duration.second = secs % 60;
        let remainder = Math.floor((secs - duration.second) / 60);
        duration.minute = remainder % 60;
        remainder = Math.floor((remainder - duration.minute) / 60);
        duration.hour = remainder % 24;
        duration.day = Math.floor((remainder - duration.hour) / 24);
        return duration;
    }

    to_duration({total, day, hour, minute, second}) {
        return `PT${total > 0 ? "-" : ""}${day}D${hour}H${minute}M${second}S`.replace(/\d+[DHM]/gi, m=>/^0/.test(m) ? '' : m)
    }

    get duration_update() {
        let duration = this.duration;
        // if(this.elem.id == "event-timer") console.log('duration', this.elem.dataset.duration, duration);
        if(duration.total >= 0 && this.elem.classList.contains('zero')) duration = { second: 0, total: duration.total, minute: 0, hour: 0 ,day: 0 };
        this.elem.dataset.duration = this.to_duration(duration);
        Object.entries(duration).forEach(([u,t])=>{
            const e = this.duration_elements[u];
            if(!e || !e.firstChild) return;
            switch(u) {
                case 'total':
                    return this.elem.classList[t >= 0 ? 'add' : 'remove']('over');
                case 'day':
                    e.dataset.f = e.firstChild.textContent = Math.abs(t);
                    break;
                default:
                    e.dataset.f = e.firstChild.textContent = TimeHelper.zeroPad(t, 2);
            }
            e.dataset.n = Math.abs(t);

        });
        if(this.elem.classList.contains('tabupdate')) {
            let tabtime = "";
            if(duration.day) tabtime += `${duration.day}D `;
            if(duration.hour) tabtime += `${TimeHelper.zeroPad(duration.hour, 2)}:`;
            document.title = `${tabtime}${TimeHelper.zeroPad(duration.minute,2)}:${TimeHelper.zeroPad(duration.second,2)}`;
        }
    }

    get zonetime() {
        return this.elem.dataset.zonetime || '';
    }

    get timezone() {
        const m = this.zonetime.match(/[A-Z][a-z]+\/[A-Z][a-z]+/);
        return m ? m[0] : '';
    }

    get zone() {
        const m = this.dateTime.match(/[\+\-]\d\d:?\d\d$/);
        return m ? m[0] : "+00:00";
    }

    get zoneOffset() {
        const [hour, minute] = this.zone.split(':');
        return hour * 3600 + minute * 60;
    }




}

TimeHelper.init;