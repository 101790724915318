import { QBAttribute } from "../attribute";

export class QBEditField {
    constructor(element, edit,  ...events) {
        element = element.closest(this.constructor.match);
        this.edit = edit;
        this.element = element;
        this.attribute = new QBAttribute(element, edit, this)

        if(element.closest('.translatable')) this.translatable = true;

        this.events = events;
        if(!element.field) {
            events.forEach(evt=>element.addEventListener(evt, this, true));
            element.field = this;
        }
        // console.log(`${this.constructor.name}#constructor`, this);
    }

    destructor() {
        console.log('QBEditField#destructor', this);
        if(!this.element) return;
        delete this.element.field;
        this.events.forEach(evt=>this.element.removeEventListener(evt, this, true));
    }

    get item_element() { return this.attribute.item_element }
    get index() { return this.attribute.index }

    get hasFocus() { return document.activeElement == this.element }

    handleEvent(evt) {
        if(this[evt.type]) this[evt.type](evt);
        else console.log('Unhandled', evt);
    }

    set changed(status) {
        if(this.item_element) this.item_element.classList[status ? 'add' : 'remove']('pending');
    }

    get changed() {
        if(this.item_element)
            return this.item_element.classList.contains('pending');
    }

    set transmitting(status) {
        if(this.item_element) this.item_element.classList[status ? 'add' : 'remove']('transmitting');
    }
    get transmitting() {
        if(this.item_element) return this.item_element.classList.contains('transmitting');
    }

    onclick(evt) {
        // this.element.click();
    }

    onfocus(evt) {
        console.log(this.constructor.name, 'context open', this, evt);
        this.context_open();
    }

    focus() {
        this.element.focus();
    }

    onblur(evt) {
        // console.log('blur', this, evt);
        this.context_close();
        delete this.edit.field;
        delete this.edit;
    }

    oninput(evt) {
        this.changed = true;
        console.log('oninput', this, this.changed);
    }

    ontextblur(evt) {
        console.log('textblur', this);
        if(this.changed) this.put();
        this.changed = false;
    }

    get lang() {
        return (this.element.closest('[lang], [data-attribute]') || {}).lang;
    }

    get dataset() { return this.element.dataset }

    get params() {}

    put(params) {
        if(this.transmitting) return;
        this.transmitting = true;
        params = Object.assign({}, params, { index: this.index, source_lang: this.lang }, this.dataset, this.params);

        const promise = this.attribute.put(params);
        promise.then(()=>QB.notice = __("%{attribute} saved", { attribute: this.attribute.name }));

        promise.finally(()=>setTimeout(()=>this.transmitting=false, 2000));
        return promise;
    }

    receive_update({attribute_element, html}) {
        console.log("EditField#receive_update");
        attribute_element.innerHTML = html; // TODO find changes then update just these
    }


    get language() {
        const container = document.querySelector('body > footer > .side:first-child');
        let details = container.querySelector(".language");
        if(details) {
            if (this._contexts && this._contexts.indexOf(details) != -1) return;
            details.remove();
        }
        const langs = Object.assign({}, window.langs),
            { symbol, name } = langs[this.lang] || { symbol: '🏳️', name: __('Agnostic') };
        delete langs[this.lang];
        const buttons = [];
        if(this.lang)  buttons.push(
            cBUTTON({
                type: 'button',
                onclick: this.language_agnostic.bind(this)
            }, cDIV('🏳️'), cDIV(__("No language")))
        );
        buttons.push(cHEADER(symbol, ' ', name));

        if(this.lang) buttons.push(
            cBUTTON({
                type: 'button',
                onclick: this.language_remove.bind(this, this.lang)
            }, cDIV('♻️'), cDIV(__("Remove")))
        );

        Object.entries(langs).forEach(([lang, {name, symbol}])=>buttons.push(
            cBUTTON({type:'button', onclick: this.language_translate.bind(this, this.lang, lang), data: {lang: lang}}, cDIV('➡️', symbol),cDIV(name))
        ));

        return cDETAILS({class: 'language', append: container, data: {lang: this.lang}, open: (details && details.open)},
            cDIV({class: 'poly-content'}, ...buttons),
            cSUMMARY({class: "qbbutton"}, "文A")
        );
    }

    language_put(disclaimer, params) {
        const { promise, reject, resolve } = Promise.create();
        if(disclaimer) QB.confirm(disclaimer.join("\n")).then(resolve);
        else resolve(true);
        promise.then(rv=>{
            console.log(rv, params);
            this.element.focus();
            if(rv) this.put(params);
        });
    }

    language_remove(lang) {
        const { name } = window.langs[lang],
            disclaimer = [
                __('Remove %{language}.', {language: name}),
                __("If you continue, you delete this language and keep the others.")
            ];
        this.language_put(disclaimer, {
            target_lang: lang,
            translation_action: 'remove'
        });
    }

    language_agnostic() {
        const disclaimer = [
            __('Transform to language agnostic.'),
            __("If you continue, you keep the current language and delete the others.")
        ];
        this.language_put(disclaimer, {translation_action: 'nolanguage'});
    }

    language_translate(from, to) {
        const { name: from_name } = window.langs[from] || { name: __('Agnostic'), symbol: '🏳️' },
            { name: to_name } = window.langs[to],
            disclaimer = from ? [
                __('Translate %{from} to %{to}.', { from: from_name, to: to_name }),
                __("If you continue, %{to} will be overwritten.", { from: from_name, to: to_name })
            ] : [
                __('Set to %{to}.', { to: to_name }),
                __("If you continue, you can translate to other languages.", { to: to_name })
            ];
        this.language_put(disclaimer, {
            source_lang: from,
            target_lang: to,
            translation_action: 'translate'
        });
    }

    contexts() {
        const rv = [];
        if(this.translatable) rv.push(this.language);
        return rv;
    }

    context_open() {
        clearTimeout(this.context_close_timeout);
        const m = function() { this.focus() }.bind(this);
        this._contexts = this.contexts();
        this._contexts.forEach(c=>c && (c.onclick=m));
    }

    context_close() {
        if(!this._contexts || !this._contexts.length) return;
        this.context_close_timeout = setTimeout(function(){
            if(!this._contexts) return;
            this._contexts.forEach(c=>c && c.remove());
            delete this._contexts;
        }.bind(this), 250);
    }
}