import {QBMediaBase} from "./base";
import {QBMediaVideo} from "./video";
export class QBMediaAudio extends QBMediaBase {
    static render({src, ...data}) { return this.__proto__.render({tag: cAUDIO({src: src, controls: true}), className: 'audio', ...data}) }

    static get mime_types() {
        return {
            "audio/webm": ["webm"],
            "audio/mpeg": ["mpga mpa mp2 mp3"],
            "audio/x-flac": ["flac"],
            "audio/wav": ["wav"],
            "application/ogg": ["ogx ogg ogm ogv oga spx opus"]
        };
    }



    get tag(){ return 'audio' }
    get type() { return 'media_auditive' }


    get initEventListeners() {
        this.element.addEventListener('canplay', this, true);
    }
}


export class QBAudio {
    static get init() {
        // window.QB.QBAudio = new QBAudio();
    }

    constructor(node) {
        this.node = node;
        this.processors = [];
        const src = node.src;
        this.context = new (window.AudioContext || window.webkitAudioContext)();
        this.buffer = new Promise((success, fail) => {
            const request = new XMLHttpRequest();
            request.open('GET', src, true);
            request.responseType = 'arraybuffer';
            request.addEventListener('load', () => this.context.decodeAudioData(request.response, success));
            request.send();
        });
    }

    catch(fn) {
        this._catch = fn;
        return this;
    }

    cut(...args) {
        this.processors.push(['cut', args]);
        return this;
    }
    get reverse () {
        this.processors.push(['reverse', []]);
        return this;
    }


    get process() {
        return new Promise((success,fail) => {
            this.buffer.then(buffer => {
                this.processors.forEach(([name, args]) => {
                    buffer = this['process_' + name](buffer, ...args);
                });
                success(buffer);
            }).catch(this._catch);
        });
    }



    process_cut(buffer, {start: _start, end: _end}) {
        const end = _end || this.node.currentTime;
        let start = _start || 0;
        if(start < 0) start = Math.max(end + start, 0);
        if (end <= start) throw('QBAudio#cut_end_before_start');

        const pc_start = start / this.node.duration,
            pc_end = end / this.node.duration;


        const length = Math.floor(buffer.length * (pc_end - pc_start)),
            offset =  buffer.length * pc_start,
            cut = this.context.createBuffer(2, length, buffer.sampleRate),
            ary = new Float32Array(length);
        buffer.copyFromChannel(ary,0,offset);
        cut.copyToChannel(ary,0,0);
        buffer.copyFromChannel(ary,1,offset);
        cut.copyToChannel(ary,1,0);
        return cut;
    };


    process_reverse(buffer) {
        Array.prototype.reverse.call( buffer.getChannelData(0) );
        Array.prototype.reverse.call( buffer.getChannelData(1) );
        return buffer;
    }

    to_url(callback) {
        this.process.then(buffer=>callback(URL.createObjectURL( this.bufferToWave(buffer) )));
        return this;
    }


    bufferToWave(abuffer) {
        var numOfChan = abuffer.numberOfChannels,
            length = abuffer.length * numOfChan * 2 + 44,
            buffer = new ArrayBuffer(length),
            view = new DataView(buffer),
            channels = [], i, sample,
            offset = 0,
            pos = 0;

        // write WAVE header
        setUint32(0x46464952);                         // "RIFF"
        setUint32(length - 8);                         // file length - 8
        setUint32(0x45564157);                         // "WAVE"

        setUint32(0x20746d66);                         // "fmt " chunk
        setUint32(16);                                 // length = 16
        setUint16(1);                                  // PCM (uncompressed)
        setUint16(numOfChan);
        setUint32(abuffer.sampleRate);
        setUint32(abuffer.sampleRate * 2 * numOfChan); // avg. bytes/sec
        setUint16(numOfChan * 2);                      // block-align
        setUint16(16);                                 // 16-bit (hardcoded in this demo)

        setUint32(0x61746164);                         // "data" - chunk
        setUint32(length - pos - 4);                   // chunk length

        // write interleaved data
        for(i = 0; i < abuffer.numberOfChannels; i++)
            channels.push(abuffer.getChannelData(i));

        while(pos < length) {
            for(i = 0; i < numOfChan; i++) {             // interleave channels
                sample = Math.max(-1, Math.min(1, channels[i][offset])); // clamp
                sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767)|0; // scale to 16-bit signed int
                view.setInt16(pos, sample, true);          // write 16-bit sample
                pos += 2;
            }
            offset++                                     // next source sample
        }

        // create Blob
        return new Blob([buffer], {type: "audio/wav"});

        function setUint16(data) {
            view.setUint16(pos, data, true);
            pos += 2;
        }

        function setUint32(data) {
            view.setUint32(pos, data, true);
            pos += 4;
        }
    }








// get current_audio () {
//     const audio_context = this.audio_context;
//     if(this._current_audio) return Promise.resolve(this._current_audio);
//     this._current_audio = this.audio_context.createBufferSource();
//     this._current_audio.onended = ()=>this._current_audio=undefined;
//     return this.current_audio_buffer.then(buffer=>{
//         this._current_audio.buffer = buffer;
//         this._current_audio.connect(audio_context.destination);
//         return this._current_audio;
//     });
// }

// start (...args) {
//     this.current_audio.then(source=>{
//         this.current_audio_start_time = 1 * new Date();
//         source.start(...args);
//     })
// }

// get play() { this.start() }
//
// get suspend() { this.audio_context }
//
// get currentTime () {
//     if(!this.current_audio_start_time) return 0;
//     return 1 * new Date() - this.current_audio_start_time;
// }
//
// get stop () {
//     this.current_audio.then(source=>{
//         this.current_audio_start_time = undefined;
//         source.stop();
//     })
// }
// TODO: Audio class
// Observer replacement mit audio class
// Central player,
// Button, slider, timing(0.1 sekunden genau), mute, volume
// method reverse

//QB.Media.audio_context.getOutputTimestamp()
// normalize https://developer.mozilla.org/en-US/docs/Web/API/ConvolverNode
// VOLUME
    /*
    const aCtx = new AudioContext();

    const gainNode = aCtx.createGain();
    gainNode.gain.value = 0.1; // setting it to 10%
    gainNode.connect(aCtx.destination);

    let source = aCtx.createBufferSource();
    let buf;
    fetch('https://dl.dropboxusercontent.com/s/knpo4d2yooe2u4h/tank_driven.wav') // can be XHR as well
    .then(resp => resp.arrayBuffer())
    .then(buf => aCtx.decodeAudioData(buf)) // can be callback as well
    .then(decoded => {
    source.buffer = buf = decoded;
    source.loop = true;
    source.connect(gainNode);

    check.disabled = false;
    });


      gainNode.gain.setValueAtTime(0, audioCtx.currentTime);
     */

    /*
        var context = new (window.AudioContext || window.webkitAudioContext)();
        var audioSrc = 'https://mfbx9da4.github.io/assets/audio/dope-drum-loop_C_major.wav'

        fetch(audioSrc, onSuccess)

        function fetch (url, resolve) {
            var request = new XMLHttpRequest();
            request.open('GET', url, true);
            request.responseType = 'arraybuffer';
            request.onload = function () { resolve(request) }
            request.send()
        }

        function onSuccess (request) {
            var audioData = request.response;
            context.decodeAudioData(audioData, onBuffer, onDecodeBufferError)
        }

        function onBuffer (buffer) {
            var source = context.createBufferSource();
            console.info('Got the buffer', buffer);
            source.buffer = buffer;
            source.connect(context.destination);
            source.loop = true;
            source.start()
        }

        function onDecodeBufferError (e) {
            console.log('Error decoding buffer: ' + e.message);
            console.log(e);
        }


    var context = new AudioContext(),
        request = new XMLHttpRequest();
    request.open('GET', 'path/to/audio.mp3', true);
    request.responseType = 'arraybuffer';
    request.addEventListener('load', function(){
        context.decodeAudioData(request.response, function(buffer){
            var source = context.createBufferSource();
            Array.prototype.reverse.call( buffer.getChannelData(0) );
            Array.prototype.reverse.call( buffer.getChannelData(1) );
            source.buffer = buffer;
        });
    });

     */


}


