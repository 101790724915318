// import QBFieldTypeCheckbox from "./field_type/choice";
// import QBFieldTypeRadio from "./field_type/radio";
// import QBFieldTypeText from "./field_type/text";
// import QBFieldTypeList from "./field_type/list";
// import QBFieldTypeNumber from "./field_type/number";
// import QBFieldTypeTrix from "./field_type/trix";



export class QBAttribute {
    constructor(element, edit, field) {
        this.edit = edit;
        this.element = element.closest("[data-attribute]");
        this.index_element = element.closest("[data-index]");
        this.field = field;
    }

    destructor() {
        delete this.element.edit;
        delete this.element;
    }

    get item_element() { return this.index_element || this.element }

    get dataset() { return this.element.dataset }

    get name (){ return this.dataset.attribute }

    get translatable () { return this.dataset.translatable }

    get index() {
        if(this.index_element) return parseInt(this.index_element.dataset.index) || undefined
    }


    put(params) {
        const promise = this.edit.put(Object.assign({}, this.dataset, this.params, params));
        return promise;
    }

    get params() { if(this.index_element) return Object.assign({}, this.index_element.dataset) }

}