import {QBEditField} from "./base";
export class QBFieldTypeContenteditable extends QBEditField {
    static get match() {return '[contenteditable].text-only'}
    constructor(field, edit) {
        // field.contentEditable = true;
        super(field, edit, 'input');
        this.element.contentEditable = true;
        // this.element.setAttribute('contenteditable', 'true');
    }


    onclick(evt) {
        console.log(evt)
        // if(evt) evt.preventDefault();
        // this.element.contentEditable = true;
        // this.element.setAttribute('contenteditable', 'true');
    }

    handleEvent(evt) {
        console.log(evt)
        switch(evt.type) {
            case "focus":
                return this.onfocus(evt);
            case "input":
                return this.oninput(evt);
            case "blur":
                return this.ontextblur(evt);
        }
        super.handleEvent(evt);
    }

    // context_open(evt) {}
    onfocus(evt) {
        const selection = window.getSelection(),
            range = document.createRange();
        selection.removeAllRanges();
        range.selectNodeContents(this.element);
        selection.addRange(range);
        selection.collapseToEnd();
        super.onfocus(evt);
    }

    onblur(evt) {
        console.log(evt)
        this.element.textContent = this.element.textContent;
        this.ontextblur(evt);
        super.onblur(evt);
        this.element.contentEditable = false;
        // this.element.removeAttribute('contenteditable');
    };

    get params() { return { content: this.element.textContent } }

}