Array.prototype.uniq = function () {return this.filter((value, index, self) => self.indexOf(value) === index)};

Object.type = (object) => {
    if(object === undefined) return "undefined";
    else if(object === null) return "null";
    return object.__proto__.constructor.name;
}
    // return Object.prototype.toString.call(object).match(/\s([^\]]+)/)[1];


Object.slice = (object, ...keys) => Object.fromEntries(Object.entries(object).filter(([k,v])=>keys.indexOf(k) != -1));

Object.compact = (object) =>  Object.fromEntries(Object.entries(object).filter(([k,v])=>v!==undefined && v!==null));

Object.dig = (object, ...keys) => {
    let digged = object;
    for (const key of keys) {
        if (typeof digged === 'undefined' || digged === null) return undefined;
        if (typeof key === 'function') digged = key(digged);
        else digged = digged[key];
    }
    return digged;
};

if(!Object.fromEntries) {
    Object.fromEntries = (obj) => obj.reduce((acc, [key, value]) => ({...acc, [key]: value}), {});
}

Promise.create = () => {
    const rv = {};
    rv.promise = new Promise((resolve, reject) => { rv.resolve = resolve; rv.reject = reject});
    return rv;
}

window.getScrollParent = (node) => {
    const regex = /(auto|scroll)/;
    const parents = (_node, ps) => {
        if (_node.parentNode === null) { return ps; }
        return parents(_node.parentNode, ps.concat([_node]));
    };

    const style = (_node, prop) => getComputedStyle(_node, null).getPropertyValue(prop);
    const overflow = _node => style(_node, 'overflow') + style(_node, 'overflow-y') + style(_node, 'overflow-x');
    const scroll = _node => regex.test(overflow(_node));

    /* eslint-disable consistent-return */
    const scrollParent = (_node) => {
        if (!(_node instanceof HTMLElement || _node instanceof SVGElement)) {
            return;
        }

        const ps = parents(_node.parentNode, []);

        for (let i = 0; i < ps.length; i += 1) {
            if (scroll(ps[i])) {
                return ps[i];
            }
        }

        return document.scrollingElement || document.documentElement;
    };

    return scrollParent(node);
    /* eslint-enable consistent-return */
};


RegExp.escape = s=>s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');