import QBEditMedia from "./base";
import {QBMediaAudio} from "../audio";

export default class QBEditMediaAuditive extends QBEditMedia {
    get mime_types() {
        return Object.keys(QBMediaAudio.mime_types);
    }
    get type() { return 'auditive' }
    get name() { return __('Auditive media') }
    get attribute ()  { return 'media_auditive' }


    get select_options() {
        return [1,2,3,4,5,6,7,8].map(
                (n)=>[n__("1 audio", "%{n} rows", n, {n}), n]
            );
    }
}
