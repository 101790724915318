// delete this.Trix.config.textAttributes.href;
// delete this.Trix.config.blockAttributes.heading1;
// delete this.Trix.config.blockAttributes.code;
// delete this.Trix.config.blockAttributes.attachmentGallery;
import Trix from  "@/trix";

import './tags';

(function() {
    let attributes;
    Trix.config.blockAttributes = attributes = {
        "default": {
            tagName: "div",
            parse: false
        },
        quote: {
            tagName: "blockquote",
            nestable: true
        },
        heading1: {
            tagName: "h1",
            terminal: true,
            breakOnReturn: true,
            group: false
        },
        code: {
            tagName: "pre",
            terminal: true,
            text: {
                plaintext: true
            }
        },
        bulletList: {
            tagName: "ul",
            parse: false
        },
        bullet: {
            tagName: "li",
            listAttribute: "bulletList",
            group: false,
            nestable: true,
            test: function(element) {
                return Trix.tagName(element.parentNode) === attributes[this.listAttribute].tagName;
            }
        },
        numberList: {
            tagName: "ol",
            parse: false
        },
        number: {
            tagName: "li",
            listAttribute: "numberList",
            group: false,
            nestable: true,
            test: function(element) {
                return Trix.tagName(element.parentNode) === attributes[this.listAttribute].tagName;
            }
        },
        abcList: {
            tagName: "menu",
            parse: false
        },
        abc: {
            tagName: "li",
            listAttribute: "abcList",
            group: false,
            nestable: true,
            test: function(element) {
                return Trix.tagName(element.parentNode) === attributes[this.listAttribute].tagName;
            }
        },

        attachmentGallery: {
            tagName: "div",
            exclusive: true,
            terminal: true,
            parse: false,
            group: false
        }
    };
    Trix.config.textAttributes = {
        bold: {
            tagName: "strong",
            inheritable: true,
            parser: function(element) {
                var style;
                style = window.getComputedStyle(element);
                return style["fontWeight"] === "bold" || style["fontWeight"] >= 600;
            }
        },
        italic: {
            tagName: "em",
            inheritable: true,
            parser: function(element) {
                var style;
                style = window.getComputedStyle(element);
                return style["fontStyle"] === "italic";
            }
        },
        href: {
            groupTagName: "a",
            parser: function(element) {
                var attachmentSelector, link, matchingSelector;
                attachmentSelector = Trix.AttachmentView.attachmentSelector;
                matchingSelector = "a:not(" + attachmentSelector + ")";
                if (link = Trix.findClosestElementFromNode(element, {
                    matchingSelector: matchingSelector
                })) {
                    return link.getAttribute("href");
                }
            }
        },
        strike: {
            tagName: "del",
            inheritable: true
        },
        underline: {
            tagName: "u",
            inheritable: true
        },
        frozen: {
            style: {
                "backgroundColor": "highlight"
            }
        }
    };
    Trix.config.toolbar = {
        elements: {
            "text-tools": {
                bold: 'b',
                italic: 'b',
                strike: true,
                underline: true,
                // link: {attribute: 'href', action: 'link', key: 'k'}
            },
            "block-tools": {
                // heading1: true,
                quote: true,
                // code: true,
                bullet: true,
                number: true,
                abc: true,
                outdent: {icon: "decrease-nesting-level", action: "decreaseNestingLevel"},
                indent: {icon: "increase-nesting-level", action: "increaseNestingLevel"}
            },
            // "file-tools": {
            //     attachFiles: {icon: 'attach'}
            // },
            spacer: true,
            "history-tools": {
                undo: {action: 'undo', key: 'z' },
                redo: {action: 'redo',key: "shift+z" }
            }

        },

        getDefaultHTML: function() {
            const lang = Trix.config.lang;

            const mkbutton = ([name, params]) => {
                let icon = name;
                const options = {
                    type: "button",
                    'class': `trix-button trix-button--icon`,
                    'data-trix-attribute': name,
                    title: lang[name],
                    tabindex: "-1"
                };
                switch (typeof params) {
                    case 'string':
                        options['data-trix-key'] = params;
                        break;
                    case 'boolean':
                        break;
                    default:
                        if ('attribute' in params) options['data-trix-attribute'] = params.attribute;
                        if ('key' in params) options['data-trix-key'] = params.key;
                        if ('action' in params) options['data-trix-action'] = params.action;
                        if ('icon' in params) icon = params.icon;
                }

                options['class'] += ` trix-button--icon-${icon}`;

                return cBUTTON(options);
            };

            const menu = cDIV({class: "trix-button-row button-row"});
            Object.entries(Trix.config.toolbar.elements).forEach(([name, buttons]) => {
                if(name == 'spacer') {
                    if (buttons) cSPAN({class: "trix-button-group-spacer", append: menu});
                    return;
                }
                const group = cSPAN({class: `trix-button-group trix-button-group--${name}`, 'data-trix-button-group': name, append: menu});
                group.append(...Object.entries(buttons).map(mkbutton));

            });

            const dialogs = new Fragment("<div class=\"trix-dialogs\" data-trix-dialogs>\n " +
                "   <div class=\"trix-dialog trix-dialog--link\" data-trix-dialog=\"href\" data-trix-dialog-attribute=\"href\">\n   " +
                "       <div class=\"trix-dialog__link-fields\">\n     " +
                "           <input type=\"url\" name=\"href\" class=\"trix-input trix-input--dialog\" placeholder=\"" + lang.urlPlaceholder + "\" aria-label=\"" + lang.url + "\" required data-trix-input>\n     " +
                "           <div class=\"trix-button-group\">\n       " +
                "               <input type=\"button\" class=\"trix-button trix-button--dialog\" value=\"" + lang.link + "\" data-trix-method=\"setAttribute\">\n        " +
                "               <input type=\"button\" class=\"trix-button trix-button--dialog\" value=\"" + lang.unlink + "\" data-trix-method=\"removeAttribute\">\n      " +
                "           </div>\n    " +
                "       </div>\n  " +
                "   </div>\n" +
                "</div>");



            return cDIV(menu, dialogs.frag).innerHTML;
        }
    };
})();
Trix.init();
export default Trix;

