if(Trix.config.css === undefined) Trix.config.css = {
    attachment: "attachment",
    attachmentCaption: "attachment__caption",
    attachmentCaptionEditor: "attachment__caption-editor",
    attachmentMetadata: "attachment__metadata",
    attachmentMetadataContainer: "attachment__metadata-container",
    attachmentName: "attachment__name",
    attachmentProgress: "attachment__progress",
    attachmentSize: "attachment__size",
    attachmentToolbar: "attachment__toolbar",
    attachmentGallery: "attachment-gallery"
};
if(Trix.config.fileSize === undefined) {
    const lang = Trix.config.lang;
    const sizes = [lang.bytes, lang.KzB, lang.MB, lang.GB, lang.TB, lang.PB];

    Trix.config.fileSize = {
        prefix: "IEC",
        precision: 2,
        formatter: function(number) {
            var base, exp, humanSize, string, withoutInsignificantZeros;
            switch (number) {
                case 0:
                    return "0 " + lang.bytes;
                case 1:
                    return "1 " + lang.byte;
                default:
                    base = (function() {
                        switch (this.prefix) {
                            case "SI":
                                return 1000;
                            case "IEC":
                                return 1024;
                        }
                    }).call(this);
                    exp = Math.floor(Math.log(number) / Math.log(base));
                    humanSize = number / Math.pow(base, exp);
                    string = humanSize.toFixed(this.precision);
                    withoutInsignificantZeros = string.replace(/0*$/, "").replace(/\.$/, "");
                    return withoutInsignificantZeros + " " + sizes[exp];
            }
        }
    };
}
if(Trix.config.undoInterval === undefined) Trix.config.undoInterval = 5000;

if(Trix.config.attachments === undefined) Trix.config.attachments = {
    preview: {
        presentation: "gallery",
        caption: {
            name: true,
            size: true
        }
    },
    file: {
        caption: {
            size: true
        }
    }
};

if(Trix.config.keyNames === undefined) Trix.config.keyNames = {
    "8": "backspace",
    "9": "tab",
    "13": "return",
    "27": "escape",
    "37": "left",
    "39": "right",
    "46": "delete",
    "68": "d",
    "72": "h",
    "79": "o"
};
if(Trix.config.input === undefined) Trix.config.input = {
    level2Enabled: true,
    getLevel: function() {
        if (this.level2Enabled && window.Trix.browser.supportsInputEvents) {
            return 2;
        } else {
            return 0;
        }
    },
    pickFiles: function(callback) {
        var input;
        input = window.Trix.makeElement("input", {
            type: "file",
            multiple: true,
            hidden: true,
            id: this.fileInputId
        });
        input.addEventListener("change", function() {
            callback(input.files);
            return window.Trix.removeNode(input);
        });
        window.Trix.removeNode(document.getElementById(this.fileInputId));
        document.body.appendChild(input);
        return input.click();
    },
    fileInputId: "trix-file-input-" + (Date.now().toString(16))
};